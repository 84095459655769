// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
import { getAuth, GoogleAuthProvider } from "firebase/auth";
import { getAnalytics } from "firebase/analytics";
// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
  apiKey: "AIzaSyCOdsxcD9c3jSh7oTAypf-9RAzBXrWDNAM",
  authDomain: "ecommerce-db2d0.firebaseapp.com",
  projectId: "ecommerce-db2d0",
  storageBucket: "ecommerce-db2d0.appspot.com",
  messagingSenderId: "416255127396",
  appId: "1:416255127396:web:46b89f70e1d2edfff19471",
  measurementId: "G-HJCTB8L0SJ"
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
const analytics = getAnalytics(app);

export const auth = getAuth(app);
export const providerGoogle = new GoogleAuthProvider();