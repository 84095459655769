import React from "react";
import { useState } from "react";
import españa from '../assets/españa.png'
import eeuu from '../assets/eeuu.webp'


const Marquee = () => {
  return (
  
      
      <div className="flex justify-center   text-2xl font-bold uppercase text-white bg-gray-600 p-1 ">
        <div className="flex flex-row justify-center">
        <h1>Ingresa desde  </h1>
        <div className="flex gap-4 ml-4">
        <button className=" ">
          <img src={españa} alt="" className="h-4 object-contain " />
        </button>
        <button className=" ">
          <img src={eeuu} alt="" className="h-4 object-contain " />
        </button>
        </div>
       
        </div>
       
        
      </div>
   
  );
};

export default Marquee;
