import React from 'react';
import { Link } from 'react-router-dom';
const NotFound = () => {
  return (
    <div className="flex items-center justify-center h-screen bg-gradient-to-r from-black via-red-900 to-black">
      <div className="text-center p-10 bg-gray-800 bg-opacity-90 rounded-lg shadow-lg">
        <h1 className="text-9xl font-extrabold text-transparent bg-clip-text bg-gradient-to-r from-red-500 to-red-700">
          404
        </h1>
        <p className="text-2xl font-semibold text-white mt-4">
          ¡Oops! Página no encontrada
        </p>
        <p className="text-gray-300 mt-2">
          Lo sentimos, pero la página que estás buscando no existe o ha sido movida.
        </p>
        <Link
          to="/"
          className="mt-6 inline-block px-6 py-3 text-lg font-medium text-white bg-red-600 rounded-full hover:bg-red-700 transition-colors duration-300"
        >
          Volver al inicio
        </Link>
      </div>
    </div>
  );
}

export default NotFound;
