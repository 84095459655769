import {
    createSlice,
    createAsyncThunk
} from '@reduxjs/toolkit'
import api from '../../api/api'



export const get_wallet = createAsyncThunk(
    'customer/wallet',
    async (customerId, {
        rejectWithValue,
        fulfillWithValue
    }) => {
        try {
            const {
                data
            } = await api.get(`/wallet/${customerId}`)
            return fulfillWithValue(data)
        } catch (error) {
            return rejectWithValue(error.response.data); 
        }
    }
)


export const walletReducer = createSlice({
    name: 'wallet',
    initialState: {
       
        myWallet:[],
        errorMessage: '',
        successMessage: '',
        
    },
    reducers: {
        messageClear: (state, _) => {
            state.errorMessage = ''
            state.successMessage = ''
        }
    },
    extraReducers: (builder) => {
        builder
        
            .addCase(get_wallet.fulfilled, (state, { payload }) => {
                state.myWallet = payload.balance 
            })
            .addCase(get_wallet.rejected, (state, { payload }) => {
                state.errorMessage = payload ? payload.message : 'Error al obtener la cartera';
            })
    }
})

export const {
    messageClear
} = walletReducer.actions
export default walletReducer.reducer