import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import api from '../../api/api';

// Obtener notificaciones
export const get_notification = createAsyncThunk(
    'notification/get_notification',
    async (userId, { rejectWithValue, fulfillWithValue }) => {
        try {
            const { data } = await api.get(`/user/notifications/${userId}`, { withCredentials: true });
            return fulfillWithValue(data);
        } catch (error) {
            return rejectWithValue(error.response.data);
        }
    }
);

export const markAsRead = createAsyncThunk(
    'notification/markAsRead',
    async (notificationId, { rejectWithValue }) => {
        try {
            const { data } = await api.patch(`/user/notifiactions-read/${notificationId}`, { withCredentials: true });
            return data; 
        } catch (error) {
            return rejectWithValue(error.response.data);
        }
    }
);


export const deleteNotification = createAsyncThunk(
    'notification/deleteNotification',
    async (notificationId, { rejectWithValue }) => {
        try {
            await api.delete(`/user/delete-notifications/${notificationId}`, { withCredentials: true });
            return notificationId; 
        } catch (error) {
            return rejectWithValue(error.response.data);
        }
    }
);

export const notificationReducer = createSlice({
    name: 'notification',
    initialState: {
        successMessage: '',
        errorMessage: '',
        loader: false,
        notifications: [],
        product: '',
        totalNotifications: 0,
    },
    reducers: {
        messageClear: (state) => {
            state.errorMessage = '';
            state.successMessage = '';
        },
    },
    extraReducers: (builder) => {
        builder
            .addCase(get_notification.pending, (state) => {
                state.loader = true;
            })
            .addCase(get_notification.fulfilled, (state, action) => {
                state.loader = false;
                state.notifications = action.payload;
                state.totalNotifications = action.payload.length;
            })
            .addCase(get_notification.rejected, (state, action) => {
                state.loader = false;
                state.errorMessage = action.payload;
            })
            .addCase(markAsRead.fulfilled, (state, action) => {
                const index = state.notifications.findIndex(notification => notification._id === action.payload._id);
                if (index !== -1) {
                    state.notifications[index] = action.payload; // Actualiza la notificación
                }
            })
            .addCase(deleteNotification.fulfilled, (state, action) => {
                state.notifications = state.notifications.filter(notification => notification._id !== action.payload);
                state.totalNotifications = state.notifications.length; // Actualiza el total de notificaciones
            });
    },
});

export const { messageClear } = notificationReducer.actions;
export default notificationReducer.reducer;
