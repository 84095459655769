import React, { useEffect, useState, useRef } from "react";
import { Link, useNavigate, useLocation } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { signOut } from "firebase/auth";
import { auth } from "../config/firebase";
import api from "../api/api";
import toast from "react-hot-toast";
import Header from "./Header";
import { GoSearch, GoHeart, GoBell, GoHeartFill } from "react-icons/go";
import { RiShoppingCart2Line, RiShoppingCart2Fill } from "react-icons/ri";
import { MdCompare } from "react-icons/md";
import { FaArrowsSpin } from "react-icons/fa6";
import {reset_count,get_card_products,get_compare_products,get_wishlist_products,
} from "../store/reducers/cardReducer";
import { user_reset } from "../store/reducers/authReducer";
import {advanced_search,emptyAdvancedSearch,get_product,} from "../store/reducers/homeReducer";
import {disconnectSocket,receivedBuy,initiateSocket} from "./notifications/notifications";

const links = [
  { title: "Productos", path: "/products" },
  { title: "Mi perfil", path: "/dashboard" },
  { title: "Blog", path: "/blog" },
];

const Navbar = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const dispatch = useDispatch();
  const { advanced_search_prd } = useSelector((state) => state.home);
  const { userInfo } = useSelector((state) => state.auth);
  const { card_product_count, wishlist_count, comparelist_count } = useSelector(
    (state) => state.card
  );

  const [searchValue, setSearchValue] = useState("");
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const [isSearchOpen, setIsSearchOpen] = useState(false);
  const [count, setCount] = useState(0);
  const [notifications, setNotifications] = useState([]);
  const [showNotifications, setShowNotifications] = useState(false);
  const [isSearchResultsVisible, setIsSearchResultsVisible] = useState(false);
  const [headerVisible, setHeaderVisible] = useState(true);
  const [backgroundColor, setBackgroundColor] = useState("bg-transparent");
  const [isHome, setIsHome] = useState(false);
  const notificationRef = useRef(null); 
  const menuRef = useRef(null);
  const searchRef = useRef(null);
  const heroSectionRef = useRef(null);
  const navbarRef = useRef(null);

const userId = userInfo.id




useEffect(() => {
  if (userId) {
    initiateSocket({
      id: userId,
      onNotification: (data) => {
        
        setNotifications((prev) => [...prev, data]); 
      },
    });
  }
}, [userId]);


useEffect(() => {
  console.log("Estado de las notificaciones actualizado:", notifications);
}, [notifications]);


const removeNotification = (index) => {
  setNotifications((prev) => prev.filter((_, i) => i !== index)); 
};


useEffect(() => {
  const handleClickOutside = (event) => {
    if (notificationRef.current && !notificationRef.current.contains(event.target)) {
      setShowNotifications(false); 
    }
  };

  document.addEventListener("mousedown", handleClickOutside);
  return () => {
    document.removeEventListener("mousedown", handleClickOutside);
  };
}, []);




  useEffect(() => {
    setIsHome(location.pathname === "/");
  }, [location]);

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (menuRef.current && !menuRef.current.contains(event.target)) {
        setIsMenuOpen(false);
      }
    };

    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  useEffect(() => {
    if (searchValue.trim().length > 0) {
      dispatch(advanced_search(searchValue.trim()));
    } else {
      dispatch(emptyAdvancedSearch());
    }
  }, [dispatch, searchValue]);

  useEffect(() => {
    if (userInfo) {
      dispatch(get_card_products(userInfo.id));
      dispatch(get_wishlist_products(userInfo.id));
      dispatch(get_compare_products(userInfo.id));
    }
  }, [dispatch, userInfo]);

  useEffect(() => {
    const handleScroll = () => {
      const heroSection = heroSectionRef.current;
      const scrollTop = window.scrollY;

      if (heroSection && isHome) {
        const heroHeight = heroSection.offsetHeight;

        if (scrollTop > heroHeight) {
          setHeaderVisible(false);
          setBackgroundColor("bg-black");
        } else {
          setHeaderVisible(true);
        }
      } else {
        setHeaderVisible(true);
        setBackgroundColor("bg-black");
      }
    };

    window.addEventListener("scroll", handleScroll);
    handleScroll();

    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, [isHome]);

  const searchAdvanced = (value) => {
    if (!value) {
        console.error("No se proporcionó un valor para buscar");
        return; 
    }
    const formattedValue = value.split(" ").join("-").toLowerCase();
    navigate(`/product/details/${formattedValue}`);
    dispatch(emptyAdvancedSearch());
    setIsSearchResultsVisible(false);
};

  const logout = async () => {
    try {
      await signOut(auth);
      await api.post("/logout");
      localStorage.removeItem("token");
      localStorage.removeItem("refreshToken");
      dispatch(user_reset());
      dispatch(reset_count());
      disconnectSocket();
      navigate("/login");
    } catch (error) {
      console.log(error.response);
      console.log(error.message);
    }
  };

  const toggleNotifications = () => {
    setShowNotifications(!showNotifications);
   
    setCount(0);
  };

  const handleClickOutside = (event) => {
    if (searchRef.current && !searchRef.current.contains(event.target)) {
      setIsSearchResultsVisible(false);
    }
  };

  useEffect(() => {
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  const handleSearchFocus = () => {
    if (searchValue.trim().length > 0) {
      setIsSearchResultsVisible(true);
    }
  };

  const handleSearchChange = (e) => {
    const value = e.target.value;
    setSearchValue(value);
    setIsSearchResultsVisible(value.trim().length > 0);
  };

  const renderLogo = () => (
    <Link to={"/"}>
      <img
        src={"../../image/logoRojoBlanco.png"}
        alt="LogoPadelLove"
        className="h-9 w-auto hidden md:block  object-contain"
      />
      <img
        src={"../../image/paletitaRoja.png"}
        alt="LogoPadelLove"
        className="h-9 w-auto md:hidden object-contain"
      />
    </Link>
  );

  const renderLinks = () => (
    <ul className="hidden lg:flex gap-4 items-center ">
      {links.map((link) => (
        <li key={link.title} className="uppercase">
          <Link to={link.path}>{link.title}</Link>
        </li>
      ))}
    </ul>
  );

  const renderSearch = () => (
    <div className="relative hidden md:flex items-center" ref={searchRef}>
      <input
        placeholder="BUSCAR"
        onChange={handleSearchChange}
        onFocus={handleSearchFocus}
        value={searchValue}
        type="text"
        className="p-1 pl-3 pr-8 w-40 placeholder:text-sm rounded-full border border-white bg-transparent"
      />
      <GoSearch size={20} className="absolute right-3 bottom-2" />
      {renderSearchResults()}
    </div>
  );

  const renderSearchResults = () =>
    isSearchResultsVisible &&
    advanced_search_prd.length > 0 && (
      <div className="absolute top-[48px] z-10 left-0 overflow-y-auto max-h-[400px] w-[350px]">
        <div className="flex gap-0 flex-col">
          {advanced_search_prd.map((item, i) => (
            <div
              key={i}
              onClick={() => searchAdvanced(item.name)}
              className="flex items-center font-medium capitalize w-full bg-gray-900 hover:bg-secondaryRed cursor-pointer border border-gray-800 gap-2 py-1 px-4"
            >
              <img
                src={item.images}
                alt="img"
                className="w-16 h-16 object-cover"
              />
              <span>{item.name}</span>
            </div>
          ))}
        </div>
      </div>
    );
    const renderIcons = () => (
      <div className="flex items-center gap-4">
        {renderNotificationIcon()}
        {renderCompareIcon()}
        {renderWishlistIcon()}
        {renderCartIcon()}
      </div>
    );
    
    const renderNotificationIcon = () =>
      userInfo && (
        <div className="relative flex justify-center items-center cursor-pointer w-[35px] h-[35px]" ref={notificationRef}>
          <div onClick={toggleNotifications}>
            {notifications.length > 0 ? (
              <>
                <GoBell size={20} />
                <div className="w-[20px] h-[20px] absolute bg-red rounded-full text-white flex justify-center items-center -top-[3px] -right-[5px]">
                  {notifications.length}
                </div>
              </>
            ) : (
              <GoBell size={20} />
            )}
          </div>
          {showNotifications && renderNotifications()}
        </div>
      );
  
    const renderNotifications = () => (
      <div className="absolute top-full right-0 mt-2 w-64 bg-white border rounded shadow-lg">
        {notifications.length > 0 ? (
          notifications.map((notification, index) => (
            <div key={index} className="p-2 border-b cursor-pointer" onClick={() => removeNotification(index)}>
              <h4 className="text-black text-sm font-semibold">{"Nueva notificación"}</h4>
              <p className="text-black text-sm">{notification.message}</p>
            </div>
          ))
        ) : (
          <div className="p-2 text-sm text-gray-500">No hay notificaciones nuevas</div>
        )}
      </div>
    );
  const renderCompareIcon = () => (
    <div className="relative flex justify-center items-center cursor-pointer w-[35px] h-[35px]">
      <Link to="/my-compare">
        {comparelist_count > 0 ? (
          <>
            <FaArrowsSpin size={20} />
            <div className="w-[20px] h-[20px] absolute bg-red rounded-full text-white flex justify-center items-center -top-[3px] -right-[5px]">
              {comparelist_count}
            </div>
          </>
        ) : (
          <FaArrowsSpin size={20} />
        )}
      </Link>
    </div>
  );

  const renderWishlistIcon = () => (
    <div className="relative flex justify-center items-center cursor-pointer w-[35px] h-[35px]">
      <Link to="/favorites">
        {wishlist_count > 0 ? (
          <>
            <GoHeartFill size={20} />
            <div className="w-[20px] h-[20px] absolute bg-red rounded-full text-white flex justify-center items-center -top-[3px] -right-[5px]">
              {wishlist_count}
            </div>
          </>
        ) : (
          <GoHeart size={20} />
        )}
      </Link>
    </div>
  );

  const renderCartIcon = () => (
    <div className="relative flex justify-center items-center cursor-pointer w-[35px] h-[35px]">
      {card_product_count > 0 ? (
        <>
          <Link to={"/card"}>
            <RiShoppingCart2Fill size={20} />
          </Link>
          <div className="w-[20px] h-[20px] absolute bg-red rounded-full text-white flex justify-center items-center -top-[3px] -right-[5px]">
            {card_product_count}
          </div>
        </>
      ) : (
        <Link to={"/card"}>
          <RiShoppingCart2Line size={20} />
        </Link>
      )}
    </div>
  );

  const renderLogoutButton = () =>
    userInfo && (
      <button
        onClick={logout}
        className="bg-red-500 hover:bg-red-600 text-white font-bold py-2 px-4 rounded"
      >
        Cerrar sesión
      </button>
    );

  const renderMobileMenu = () => (
    <div>
      <button
        onClick={() => setIsMenuOpen(!isMenuOpen)}
        className="text-white focus:outline-none block lg:hidden"
      >
        <svg
          className="h-6 w-6"
          fill="none"
          viewBox="0 0 24 24"
          stroke="currentColor"
        >
          {isMenuOpen ? (
            <path
              strokeLinecap="round"
              strokeLinejoin="round"
              strokeWidth={2}
              d="M6 18L18 6M6 6l12 12"
            />
          ) : (
            <path
              strokeLinecap="round"
              strokeLinejoin="round"
              strokeWidth={2}
              d="M4 6h16M4 12h16M4 18h16"
            />
          )}
        </svg>
      </button>
    </div>
  );

  const renderMobileMenuContent = () =>
    isMenuOpen && (
      <div className="fixed top-0 left-0 w-full h-screen bg-black bg-opacity-75 flex items-center justify-center">
        <div className="p-4 rounded-lg w-72 bg-white md:w-96" ref={menuRef}>
          <ul className="flex flex-col gap-4 place-items-center">
            {links.map((link) => (
              <li key={link.title}>
                <Link
                  to={link.path}
                  className="text-black text-lg font-semibold md:text-xl"
                  onClick={() => setIsMenuOpen(false)}
                >
                  {link.title}
                </Link>
              </li>
            ))}
            {userInfo && (
              <li>
                <button
                  onClick={() => {
                    logout();
                    setIsMenuOpen(false);
                  }}
                  className="bg-red-500 hover:bg-red-600 text-white font-bold py-2 px-4 rounded md:py-3 md:px-6"
                >
                  Cerrar sesión
                </button>
              </li>
            )}
          </ul>
        </div>
      </div>
    );

  return (
    <>
      <header
        ref={navbarRef}
        className={`w-full h-28 fixed z-30 ${backgroundColor} transition-all duration-300`}
      >
        <Header
          ref={heroSectionRef}
          style={{
            visibility: headerVisible ? "visible" : "hidden",
            position: "relative",
            width: "100%",
            top: 0,
            transition: "visibility 0.3s",
            zIndex: 100,
          }}
        />
        <nav className="flex justify-between w-[85%] lg:w-[90%] mx-auto h-full items-center text-white">
          <div className="flex items-center gap-4 mb-10 ">
            {renderLogo()}
            {renderLinks()}
          </div>

          <div className="relative flex items-center gap-4 mb-10">
            <button
              className="md:hidden text-white"
              onClick={() => setIsSearchOpen(!isSearchOpen)}
            >
              <GoSearch size={20} />
            </button>
            {isSearchOpen && (
              <div ref={searchRef}>
                <input
                  placeholder="BUSCAR"
                  onChange={handleSearchChange}
                  onFocus={handleSearchFocus}
                  value={searchValue}
                  type="text"
                  className="p-1 pl-3 pr-8 w-40 placeholder:text-sm rounded-full border border-white bg-transparent md:hidden"
                />
                {renderSearchResults()}
              </div>
            )}
            {renderSearch()}
            {renderIcons()}
            <div className="hidden md:block">{renderLogoutButton()}</div>
            {renderMobileMenu()}
          </div>
        </nav>
        {renderMobileMenuContent()}
      </header>
    </>
  );
};

export default Navbar;
