import React from 'react'
import { Tag, ShoppingBag, GiftIcon, CreditCardIcon } from 'lucide-react';
import { Calendar, MapPin, Clock } from 'lucide-react';

const Gift = () => {
  const coupons = [
    { type: 'discount', value: '20%', code: 'AHORRA20', icon: Tag },
    { type: 'gift', value: '$50', code: 'REGALO50', icon: GiftIcon },
    { type: 'voucher', value: '$100', code: 'VALE100', icon: ShoppingBag },
  ];

  const bouchers = [
    { value: '$200', code: 'VOUCHER200', expirationDate: '31-08-2024' },
    { value: '$500', code: 'VOUCHER500', expirationDate: '10-09-2024' },
    { value: '$1000', code: 'VOUCHER1000', expirationDate: '17-08-2024' },
  ];

  const BoucherCard = ({ boucher }) => (
    <div className="bg-white overflow-hidden shadow-lg rounded-lg hover:shadow-xl transition-shadow duration-300 ease-in-out">
      <div className="p-6">
        <div className="flex items-center justify-between mb-4">
          <div className="flex items-center">
            <CreditCardIcon className="h-8 w-8 text-red mr-3" />
            <h2 className="text-xl font-semibold text-gray-900">Voucher</h2>
          </div>
          <span className="inline-flex items-center px-3 py-1 rounded-full text-sm font-medium bg-purple-100 text-purple-800">
            {boucher.value}
          </span>
        </div>
        <p className="text-gray-600 mb-4">
          Voucher por {boucher.value} para canjear en cualquier producto de la tienda
        </p>
        <div className="mt-4">
          <span className="text-sm font-medium text-gray-500">Código:</span>
          <span className="ml-2 text-sm font-bold text-red">{boucher.code}</span>
        </div>
        <div className="mt-2">
          <span className="text-sm font-medium text-gray-500">Válido hasta:</span>
          <span className="ml-2 text-sm text-gray-600">{boucher.expirationDate}</span>
        </div>
        <button className="mt-6 w-full bg-red text-white py-2 px-4 rounded-md hover:bg-redCategoria focus:outline-none focus:ring-2 focus:ring-purple-500 focus:ring-offset-2 transition duration-150 ease-in-out">
          Canjear Voucher
        </button>
      </div>
    </div>
  );

  return (
    <div className="min-h-screen bg-homeTexture bg-cover py-12 pt-36 px-4 sm:px-6 lg:px-8">
      <div className="max-w-7xl mx-auto">
        <h1 className="text-h1lg font-bold text-center text-white mb-12">
          Cupones y Vales de Regalo
        </h1>
        <div className="grid grid-cols-1 gap-6 sm:grid-cols-2 lg:grid-cols-3">
          {coupons.map((coupon, index) => (
            <div
              key={index}
              className="bg-white overflow-hidden shadow-lg rounded-lg hover:shadow-xl transition-shadow duration-300 ease-in-out "
            >
              <div className="p-6 flex flex-col h-full justify-between">
                <div className="flex items-center justify-between mb-4">
                  <div className="flex items-center">
                    <coupon.icon className="h-8 w-8 text-red mr-3" />
                    <h2 className="text-xl font-semibold text-gray-900">
                      {coupon.type === 'discount' ? 'Descuento' : coupon.type === 'gift' ? 'Vale Regalo' : 'Voucher'}
                    </h2>
                  </div>
                  <span className="inline-flex items-center px-3 py-1 rounded-full text-sm font-medium bg-green-100 text-green-800">
                    {coupon.value}
                  </span>
                </div>
                <p className="text-gray-600 mb-4">
                  {coupon.type === 'discount'
                    ? `Ahorra ${coupon.value} en tu próxima compra`
                    : coupon.type === 'gift'
                    ? `Vale regalo por ${coupon.value} para usar en cualquier producto`
                    : `Voucher de ${coupon.value} para canjear en tienda`}
                </p>
                <div className="mt-4">
                  <span className="text-sm font-medium text-gray-500">Código:</span>
                  <span className="ml-2 text-sm font-bold text-red">{coupon.code}</span>
                </div>
                <button className="mt-6 w-full bg-red text-white py-2 px-4 rounded-md hover:bg-redCategoria focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2 transition duration-150 ease-in-out">
                  Usar Cupón
                </button>
              </div>
            </div>
          ))}
        </div>
        
        <div className="bg-gradient-to-r from-redCategoria to-red p-4 sm:p-6 md:p-8 text-white shadow-xl rounded-lg mx-auto my-8 max-w-7xl">
      <div className="flex flex-col md:flex-row items-center justify-between">
        <div className="mb-6 md:mb-0 md:mr-6">
          <h2 className="text-h2md sm:text-4xl font-bold mb-4">Prueba Gratis de Pádel</h2>
          <p className="text-xl mb-4">¡Descubre la emoción del pádel con nuestra clase de prueba gratuita!</p>
          <ul className="space-y-2">
            <li className="flex items-center">
              <Calendar className="mr-2 h-5 w-5" />
              <span>Todos los sábados</span>
            </li>
            <li className="flex items-center">
              <Clock className="mr-2 h-5 w-5" />
              <span>10:00 AM - 11:30 AM</span>
            </li>
            <li className="flex items-center">
              <MapPin className="mr-2 h-5 w-5" />
              <span>PadeLove</span>
            </li>
          </ul>
        </div>
        <div className="w-full md:w-auto">
          <button className="w-full md:w-auto bg-white text-red font-bold py-3 px-6 rounded-full hover:bg-blue-100 transition duration-300 ease-in-out transform hover:scale-105 focus:outline-none focus:ring-2 focus:ring-white focus:ring-opacity-50">
            Reserva tu plaza
          </button>
        </div>
      </div>
      <div className="mt-6 text-sm">
        <p>* No se requiere experiencia previa. Raquetas y pelotas proporcionadas.</p>
        <p>* Plazas limitadas. Se recomienda reservar con anticipación.</p>
      </div>
    </div>

        <h2 className="text-h2md font-semibold text-white text-center m-10">Vouchers</h2>
        <div className="grid grid-cols-1 gap-6 sm:grid-cols-2 lg:grid-cols-3">
          {bouchers.map((boucher, index) => (
            <BoucherCard key={index} boucher={boucher} />
          ))}
        </div>
      </div>
    </div>
  )
}

export default Gift