import homeReducer from './reducers/homeReducer.js'
import authReducer from './reducers/authReducer.js'
import cardReducer from './reducers/cardReducer.js'
import orderReducer from './reducers/orderReducer.js'
import dashboardIndexReducer from './reducers/dashboardIndexReducer.js'
import categoryReducer from './reducers/categoryReducer.js'
import productReducer from './reducers/productReducer.js'
import dashboardSellerReducer from './reducers/dashboardSellerReducer.js'
import orderReducerSeller from './reducers/orderReducerSeller.js'
import paymentReducer from './reducers/paymentReducer.js'
import { walletReducer } from './reducers/walletReducer.js'
import authShopSlice from './reducers/authShopSlice.js'
import { PaymentPadelReducer } from './reducers/paymentPadelReducer.js'
import  notificationReducer  from './reducers/notificationReducer.js'
import  offertReducer  from './reducers/offerReducer.js'
const rootReducers = {
    home: homeReducer,
    auth: authReducer,
    card: cardReducer,
    order: orderReducer,
    wallet:walletReducer,
    dashboard: dashboardIndexReducer,
    category: categoryReducer,
    product: productReducer,
    dashboardSeller: dashboardSellerReducer,
    orderSeller: orderReducerSeller,
    payment: paymentReducer,
    paymentPadel:PaymentPadelReducer,
    authShopSlice:authShopSlice,
    notification:notificationReducer,
    offert:offertReducer
}
export default rootReducers