import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import api from "../../api/api";


export const get_offert = createAsyncThunk(
  "offert/get_offer",
  async ({ orderId, userId }, { rejectWithValue, fulfillWithValue }) => {
    try {
      const { data } = await api.get(`/seller/offer/${orderId}/${userId}`, {
        withCredentials: true,
        headers: {
          Authorization: `Bearer ${localStorage.getItem("token")}`, 
        },
      });

      
      const pendingOffers = data.filter(of => of.status === 'pendiente');
      return fulfillWithValue(pendingOffers);
    } catch (error) {
      return rejectWithValue(error.response?.data || "Error al obtener las ofertas");
    }
  }
);

export const offertReducer = createSlice({
  name: "offert",
  initialState: {
    myOffers: [],        
    errorMessage: "",
    successMessage: "",
  },
  reducers: {
    messageClear: (state) => {
      state.errorMessage = "";
      state.successMessage = "";
    },
    clearOfferMessage: (state) => {
        state.errorMessage = "";
      },
  },
  extraReducers: (builder) => {
    builder
      .addCase(get_offert.fulfilled, (state, { payload }) => {
        state.myOffers = payload;  
      })
      .addCase(get_offert.rejected, (state, { payload }) => {
        state.errorMessage = payload || "Error al obtener las ofertas";
      });
  },
});

export const { messageClear, clearOfferMessage } = offertReducer.actions;
export default offertReducer.reducer;
