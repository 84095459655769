import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { get_notification, selectNotifications, selectLoader, selectErrorMessage, deleteNotification, markAsRead } from '../../../store/reducers/notificationReducer'; // Ajusta la ruta
import './SystemNotification.css';
import { Link } from 'react-router-dom';
const SystemNotification = () => {
    const dispatch = useDispatch();
    const notifications = useSelector((state) => state.notification.notifications || []); // Asegúrate de que esto esté correcto
    const loader = useSelector((state) => state.notification.loader);
    const errorMessage = useSelector((state) => state.notification.errorMessage);
    const { userInfo } = useSelector((state) => state.auth); // Asegúrate de que esto esté correcto
    const userId = userInfo?.id;


    const [currentPage, setCurrentPage] = useState(1);
    const notificationsPerPage = 5; 



    useEffect(() => {
        if (userId) {
            dispatch(get_notification(userId));
        }
    }, [dispatch, userId]);

    const handleMarkAsRead = (id) => {
         dispatch(markAsRead(id)); 
    };

    const handleDeleteNotification = (id) => {

        dispatch(deleteNotification(id)); 
    };

    const indexOfLastNotification = currentPage * notificationsPerPage;
    const indexOfFirstNotification = indexOfLastNotification - notificationsPerPage;
    const currentNotifications = notifications.slice(indexOfFirstNotification, indexOfLastNotification);

    const totalPages = Math.ceil(notifications.length / notificationsPerPage);

    return (
        <div className="notifications-container">
            {loader && <p className="loading-message">Cargando...</p>}
            {errorMessage && <p className="error-message">Error: {errorMessage}</p>}
            <h2>Notificaciones del Sistema</h2>
            <div className="notifications-list">
                {currentNotifications.length > 0 ? (
                    currentNotifications.map(notification => {
                        
                        if (!notification || typeof notification.message !== 'string') {
                            return null; 
                        }

                        return (
                            <div key={notification._id} className={`notification ${notification.read ? 'read' : ''}`}>
                                <p>
                                    {notification.orderId}
                                        {notification.message}
                                    
                                </p>
                                <p>Tipo: {notification.type}</p>
                                <p>Fecha: {new Date(notification.createdAt).toLocaleString()}</p>
                                <div className="notification-actions">
                                    <button className="delete-button" onClick={() => handleDeleteNotification(notification._id)}>Eliminar</button>
                                </div>
                            </div>
                        );
                    })
                ) : (
                    <p>No hay notificaciones nuevas.</p>
                )}
            </div>
            <div className="pagination">
                <button onClick={() => setCurrentPage(prev => Math.max(prev - 1, 1))} disabled={currentPage === 1}>
                    Anterior
                </button>
                <span>Página {currentPage} de {totalPages}</span>
                <button onClick={() => setCurrentPage(prev => Math.min(prev + 1, totalPages))} disabled={currentPage === totalPages}>
                    Siguiente
                </button>
            </div>
        </div>
    );
};


export default SystemNotification;