import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'
import api from '../../api/api'
import { jwtDecode } from 'jwt-decode'
import axios from 'axios'
import { local_api } from '../../api/api'

export const customer_register = createAsyncThunk(
    'auth/customer_register',
    async (info, { rejectWithValue, fulfillWithValue }) => {
        try {
            // const { data } = await api.post('/customer/customer-register', info)
            const { data } = await api.post('/user/register', info)
            localStorage.setItem('token', data.token)
            return fulfillWithValue(data)
        } catch (error) {
            return rejectWithValue(error.response.data)
        }
    }
)

export const customer_login = createAsyncThunk(
    'auth/customer_login',
    async (info, { rejectWithValue, fulfillWithValue }) => {
        try {
            const { data } = await api.post('/user/login', info);
            localStorage.setItem('token', data.token);
            return fulfillWithValue(data);
        } catch (error) {
           
            if (error.response) {
               
                if (error.response.status === 429) {
                    return rejectWithValue({ message: "Has superado el límite de intentos. Intenta de nuevo más tarde." });
                } 
               
                else if (error.response.status === 400) {
                    return rejectWithValue({ message: error.response.data.message || "Email o contraseña incorrecto." });
                } else if (error.response.status === 404) {
                    return rejectWithValue({ message: error.response.data.message || "Usuario no registrado." });
                }
              
                return rejectWithValue({ message: error.response.data.message || 'Error desconocido. Intente de nuevo.' });
            }
            
            return rejectWithValue({ message: 'Error de red, intente de nuevo.' });
        }
    }
);

export const customer_login_google = createAsyncThunk(
    'auth/customer_login',
    async (result, { rejectWithValue, fulfillWithValue }) => {
        try {
            const { data } = await axios.get(`${local_api}/user/google`,{
                headers:{
                  Autorization:'Bearer ' + result.user.accessToken,
                },
              })

            localStorage.setItem('token', data.token)
            return fulfillWithValue(data)
        } catch (error) {
            
            if (error.response && error.response.data) {
                return rejectWithValue(error.response.data);
            } else {
                
                return rejectWithValue({ message: 'Error de red, intente de nuevo.' });
            }
        }
    }
);

export const add_info = createAsyncThunk(
    'auth/add_info',
    async (info, { rejectWithValue, fulfillWithValue }) => {
        try {
            const { data } = await api.put('/user/profile/update', info)
            return fulfillWithValue(data)
        } catch (error) {
            return rejectWithValue(error.response.data)
        }
    }
)

export const profile_image_upload = createAsyncThunk(
    'auth/profile_image_upload',
    async (info, { rejectWithValue, fulfillWithValue }) => {
        try {
            const { data } = await api.post('/user/profile/image/upload', info)
            return fulfillWithValue(data)
        } catch (error) {
            return rejectWithValue(error.response.data)
        }
    }
)

export const get_profile_data = createAsyncThunk(
    'auth/get_profile_data',
    async (id, { rejectWithValue, fulfillWithValue }) => {
        try {
            const { data } = await api.get(`/user/profile/${id}`)
            return fulfillWithValue(data)
        } catch (error) {
            return rejectWithValue(error.response.data)
        }
    }
)

export const changePassword = createAsyncThunk(
    'auth/changePassword',
    async (info, { rejectWithValue, fulfillWithValue }) => {
        try {
            // const { data } = await api.put(`/customer/change-password`, info)
            const { data } = await api.put(`/user/change-password`, info)
            return fulfillWithValue(data)
        } catch (error) {
            return rejectWithValue(error.response.data)
        }
    }
)

export const activateSeller = createAsyncThunk(
    'auth/activateSeller',
    async (userId, { rejectWithValue, fulfillWithValue }) => {
        try {
            const { data } = await api.put('/user/activate-seller', { userId });
            localStorage.setItem('token', data.token);
            return fulfillWithValue(data);
        } catch (error) {
            return rejectWithValue(error.response.data);
        }
    }
);

const decodeToken = (token) => {
    if (token && typeof token === 'string') {

      const parts = token.split('.');
      if (parts.length === 3) {
        try {
          const userInfo = jwtDecode(token);
          return userInfo;
        } catch (error) {
          console.error('Error al decodificar el token:', error);
          return '';
        }
      } else {
        console.error('Token no tiene el formato adecuado:', token);
        return '';
      }
    } else {
      return '';
    }
  };

export const authReducer = createSlice({
    name: 'auth',
    initialState: {
        loader: false,
        userInfo: decodeToken(localStorage.getItem('token')),
        errorMessage: '',
        successMessage: '',
        profileData: "",
        token: localStorage.getItem('token'), 
        isAuthenticated: !!localStorage.getItem('token'),
    },
    reducers: {
        messageClear: (state, _) => {
            state.errorMessage = ''
            state.successMessage = ''
        },
        user_reset: (state, _) => {
            state.userInfo = ""
        },
        updateUserRole: (state, action) => {
            state.userInfo.role = action.payload;
        },
    },
    extraReducers: (builder) => {
        builder
            .addCase(customer_register.pending, (state, action) => {
                state.loader = true;
            })
            .addCase(customer_register.rejected, (state, action) => {
                state.errorMessage = action.payload.error;
                state.loader = false;
            })
            .addCase(customer_register.fulfilled, (state, action) => {
                const userInfo = decodeToken(action.payload.token);
                state.successMessage = action.payload.message;
                state.loader = false;
                state.userInfo = userInfo;
                state.isAuthenticated = true;
            })
            .addCase(customer_login.pending, (state, action) => {
                state.loader = true;
                state.errorMessage = null;
            })
            .addCase(customer_login.rejected, (state, action) => {
                state.errorMessage = action.payload.message;
                state.loader = false;
            })
            .addCase(customer_login.fulfilled, (state, action) => {
                const userInfo = decodeToken(action.payload.token);
                state.successMessage = action.payload.message;
                state.loader = false;
                state.userInfo = userInfo;
                state.isAuthenticated = true;
            })
            .addCase(add_info.pending, (state, action) => {
                state.loader = true;
            })
            .addCase(add_info.fulfilled, (state, action) => {
                state.successMessage = action.payload.message;
                state.profileData = action.payload.profileData;
                state.loader = false;
            })
            .addCase(changePassword.rejected, (state, action) => {
                state.errorMessage = action.payload.error;
            })
            .addCase(changePassword.fulfilled, (state, action) => {
                state.successMessage = action.payload.message;
            })
            .addCase(profile_image_upload.pending, (state, action) => {
                state.loader = true;
            })
            .addCase(profile_image_upload.fulfilled, (state, action) => {
                state.loader = false;
                state.successMessage = action.payload.message;
                state.profileData = action.payload.profileData;
            })
            .addCase(get_profile_data.fulfilled, (state, action) => {
                state.profileData = action.payload.profileData;
            })
            .addCase(activateSeller.pending, (state) => {
                state.loader = true;
            })
            .addCase(activateSeller.fulfilled, (state, action) => {
                const userInfo = decodeToken(action.payload.token);
                state.successMessage = action.payload.message;
                state.loader = false;
                state.userInfo = userInfo;
            })
            .addCase(activateSeller.rejected, (state, action) => {
                state.errorMessage = action.payload.error;
                state.loader = false;
            })
            

            
    },

})

export const { messageClear, user_reset, updateUserRole } = authReducer.actions
export default authReducer.reducer