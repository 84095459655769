import React from 'react';

const ConfirmationShop = () => {
  return (
    <div className="flex justify-center items-center min-h-screen bg-gray-100 p-6 ">
      <div className="bg-white p-8 rounded-lg shadow-lg max-w-md w-full">
        <h2 className="text-2xl font-semibold mb-4 text-center text-gray-800">
          Esperando Confirmación
        </h2>
        <p className="text-center text-gray-600 mb-6">
          Su solicitud para asociar la tienda está en proceso. Por favor, espere la confirmación.
        </p>
        <div className="text-center">
          <svg
            className="animate-spin h-10 w-10 mx-auto text-gray-500"
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 24 24"
            fill="none"
            stroke="currentColor"
            strokeWidth="2"
            strokeLinecap="round"
            strokeLinejoin="round"
          >
            <circle cx="12" cy="12" r="10" />
            <path d="M4 12a8 8 0 1 1 16 0 8 8 0 0 1-16 0"></path>
          </svg>
        </div>
      </div>
    </div>
  );
};

export default ConfirmationShop;
