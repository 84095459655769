import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'
import api from '../../api/api'



export const get_seller_orders = createAsyncThunk(
    'orderSeller/get_seller_orders',
    async ({ parPage, page, searchValue, sellerId }, { rejectWithValue, fulfillWithValue }) => {
        try {
            const { data } = await api.get(`/seller/orders/${sellerId}?page=${page}&searchValue=${searchValue}&parPage=${parPage}`, { withCredentials: true })
            return fulfillWithValue(data)
        } catch (error) {
            return rejectWithValue(error.response.data)
        }
    }
)


export const get_seller_order = createAsyncThunk(
    'orderSeller/get_seller_order',
    async (orderId, { rejectWithValue, fulfillWithValue }) => {
        try {
            const { data } = await api.get(`/seller/order/${orderId}`, { withCredentials: true })
            return fulfillWithValue(data)
        } catch (error) {
            return rejectWithValue(error.response.data)
        }
    }
)



export const seller_order_status_update = createAsyncThunk(
    'orderSeller/seller_order_status_update',
    async ({ orderId, status }, { rejectWithValue, fulfillWithValue }) => {
        try {
            const { data } = await api.put(`/seller/order-status/update/${orderId}`, {status}, { withCredentials: true })
            return fulfillWithValue(data)
        } catch (error) {
            return rejectWithValue(error.response.data)
        }
    }
)

export const get_admin_orders = createAsyncThunk(
    'orderSeller/get_admin_orders',
    async ({ parPage, page, searchValue }, { rejectWithValue, fulfillWithValue }) => {
        try {
            const { data } = await api.get(`/admin/orders?page=${page}&searchValue=${searchValue}&parPage=${parPage}`, { withCredentials: true })
            return fulfillWithValue(data)
        } catch (error) {
            return rejectWithValue(error.response.data)
        }
    }
)

export const get_admin_order = createAsyncThunk(
    'orderSeller/get_admin_order',
    async (orderId, { rejectWithValue, fulfillWithValue }) => {
        try {
            const { data } = await api.get(`/admin/order/${orderId}`, { withCredentials: true })
            return fulfillWithValue(data)
        } catch (error) {
            return rejectWithValue(error.response.data)
        }
    }
)

export const admin_order_status_update = createAsyncThunk(
    'orderSeller/admin_order_status_update',
    async ({ orderId, info }, { rejectWithValue, fulfillWithValue }) => {
        try {
            const { data } = await api.put(`/admin/order-status/update/${orderId}`, info, { withCredentials: true })
            return fulfillWithValue(data)
        } catch (error) {
            return rejectWithValue(error.response.data)
        }
    }
)

export const handle_seller_response = createAsyncThunk(
    'orderSeller/handle_seller_response',
    async ({ info }, { rejectWithValue, fulfillWithValue }) => {
        try {
            console.log(info)
            const { data } = await api.post(`/seller/handle-seller-response/`, info, { withCredentials: true })
            return fulfillWithValue(data)
        } catch (error) {
            return rejectWithValue(error.response.data)
        }
    }
)



export const OrderReducerSeller = createSlice({
    name: 'orderSeller',
    initialState: {
        successMessage: '',
        errorMessage: '',
        totalOrder: 0,
        order: {},
        myOrders: []
    },
    reducers: {
        messageClear: (state, _) => {
            state.errorMessage = ""
            state.successMessage = ""
        }
    },
    extraReducers: (builder) => {
        builder
            .addCase(get_seller_orders.fulfilled, (state, { payload }) => {
                state.myOrders = payload.orders;
                state.totalOrder = payload.totalOrder;
            })
            .addCase(get_seller_order.fulfilled, (state, { payload }) => {
                state.order = payload.order;
            })
            .addCase(seller_order_status_update.rejected, (state, { payload }) => {
                state.errorMessage = payload.message;
            })
            .addCase(seller_order_status_update.fulfilled, (state, { payload }) => {
                state.successMessage = payload.message;
            })
            .addCase(get_admin_orders.fulfilled, (state, { payload }) => {
                state.myOrders = payload.orders;
                state.totalOrder = payload.totalOrder;
            })
            .addCase(get_admin_order.fulfilled, (state, { payload }) => {
                state.order = payload.order;
            })
            .addCase(admin_order_status_update.rejected, (state, { payload }) => {
                state.errorMessage = payload.message;
            })
            .addCase(admin_order_status_update.fulfilled, (state, { payload }) => {
                state.successMessage = payload.message;
            })
            .addCase(handle_seller_response.rejected, (state, { payload }) => {
                state.errorMessage = payload.message;
            })
            .addCase(handle_seller_response.fulfilled, (state, { payload }) => {
                state.successMessage = payload.message;
            })
    }


})
export const { messageClear } = OrderReducerSeller.actions
export default OrderReducerSeller.reducer