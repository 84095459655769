import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import api from '../../api/api';
import axios from 'axios'



// (Solicitud de autorización de tienda)

export const requestAuth = createAsyncThunk(
    'authShop/requestAuth',
    async ({ documents, userId, shopInfo }, { rejectWithValue, fulfillWithValue }) => {
        try {
            // Create a new FormData object
            const formData = new FormData();
            // Append shop data
            formData.append('shopName', shopInfo.shopName);
            formData.append('rif', shopInfo.rif);
            formData.append('location', shopInfo.location);
            formData.append('phone', shopInfo.phone);

            // Append files
            documents.forEach(doc => formData.append('documents', doc));
            
            // Send FormData object in the request
            const { data } = await api.post(`/request-auth-shop/${userId}`, formData, {
               
            });
            localStorage.setItem('token', data.token);
           
            return fulfillWithValue(data);
        } catch (error) {
            return rejectWithValue(error.response.data);
        }
    }
);


//  (Obtener las solicitudes pendientes de tienda)

export const pendingAuthShop = createAsyncThunk(
    'authShop/pendingAuthShop',
    async (_, { rejectWithValue, fulfillWithValue }) => {
        try {
            const { data } = await api.get('/shop/pending-auth');
            return fulfillWithValue(data);
        } catch (error) {
            return rejectWithValue(error.response.data);
        }
    }
);

// (Aprobar o rechazar la solicitud)

export const approvedShop = createAsyncThunk(
    'authShop/approvedShop',
    async ({ userId, approved }, { rejectWithValue, fulfillWithValue }) => {
        try {
            const { data } = await api.post(`/shop/approved/${userId}`, { approved });
            if (data.token) {
                localStorage.setItem('token', data.token);
                return fulfillWithValue(data);
            }
            
            return fulfillWithValue(data);
        } catch (error) {
            return rejectWithValue(error.response.data);
        }
    }
);


const authShopSlice = createSlice({
    name: 'authShop',
    initialState: {
        successMessage: '',
        errorMessage: '',
        pendingRequests: [],
        authShopDocuments: [],
        token: localStorage.getItem('token') || null, 
    },reducers: {
        messageClear: (state) => {
            state.successMessage = '';
            state.errorMessage = '';
        },


        updateToken: (state, action) => {
            state.token = action.payload;
            localStorage.setItem('token', action.payload);
          },
        
    },
    extraReducers: (builder) => {
        builder
            // requestAuth
            .addCase(requestAuth.fulfilled, (state, action) => {
                console.log('requestAuth fulfilled payload:', action.payload); 
                state.successMessage = action.payload.message || 'Operación exitosa'; 
                if (action.payload.token) {
                    state.token = action.payload.token;  
                    localStorage.setItem('token', action.payload.token);  
                }
            })
            
            .addCase(requestAuth.rejected, (state, { payload }) => {
                console.log('requestAuth rejected payload:', payload)
                state.errorMessage = payload?.message || 'Error en la operación'; 
            })
            // pendingAuthShop
            .addCase(pendingAuthShop.fulfilled, (state, { payload }) => {
                state.pendingRequests = payload;
            })
            .addCase(pendingAuthShop.rejected, (state, { payload }) => {
                state.errorMessage = payload.message;
            })
            // approvedShop
            .addCase(approvedShop.fulfilled, (state, { payload }) => {
                state.successMessage = payload.message;
            })
            .addCase(approvedShop.rejected, (state, { payload }) => {
                state.errorMessage = payload.message;
            });
    }
});

export const { messageClear, updateToken} = authShopSlice.actions;
export default authShopSlice.reducer; 