import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'
import api from '../../api/api'

export const get_seller_payemt_details = createAsyncThunk(
    'payment/get_seller_payemt_details',
    async (sellerId, { rejectWithValue, fulfillWithValue }) => {
        try {
            const { data } = await api.get(`/payment/withdrawal-balance/${sellerId}`, { withCredentials: true })
            return fulfillWithValue(data)
        } catch (error) {
            return rejectWithValue(error.response.data)
        }
    }
)


export const get_withdrawal_balances = createAsyncThunk(
    'get_withdrawal_balances',
    async (sellerId, { rejectWithValue, fulfillWithValue }) => {
      try {
        const response = await api.get(`/payment/withdrawal-balance/${sellerId}`);
       
        return fulfillWithValue(response.data.withdrawalBalances);
      } catch (error) {
        console.error("Error in get_withdrawal_balances:", error);
        return rejectWithValue(error.response.data);
      }
    }
  );
  
export const send_withdrowal_request = createAsyncThunk(
    'payment/send_withdrowal_request',
    async (info, { rejectWithValue, fulfillWithValue }) => {
        try {
            const { data } = await api.post(`/withdraw`, info, { 
                withCredentials: true })
            return fulfillWithValue(data)
        } catch (error) {
            return rejectWithValue(error.response.data)
        }
    }
)

export const get_payment_request = createAsyncThunk(
    'payment/get_payment_request',
    async ({ userId, status }, { rejectWithValue, fulfillWithValue }) => {
        try {
            const response = await api.get(`/withdrawals/${userId}`, {
                params: { status },  
                withCredentials: true
            });
            return fulfillWithValue(response.data);
        } catch (error) {
            return rejectWithValue(error.response.data);
        }
    }
);


export const archive_withdrawal = createAsyncThunk(
    "payment/archive_withdrawal",
    async (withdrawalId, { rejectWithValue }) => {
      try {
        const response = await api.put(`/withdrawals/archive/${withdrawalId}`);
        return response.data;
      } catch (error) {
        return rejectWithValue(error.response.data);
      }
    }
  );

export const confirm_payment_request = createAsyncThunk(
    'payment/confirm_payment_request',
    async (paymentId, { rejectWithValue, fulfillWithValue }) => {
        try {
            const { data } = await api.post(`/payment/request-confirm`, { paymentId }, { withCredentials: true })
            return fulfillWithValue(data)
        } catch (error) {
            return rejectWithValue(error.response.data)
        }
    }
)
export const get_withdrawals_by_user_id = createAsyncThunk(
    'payment/get_withdrawals_by_user_id',
    async ({ sellerId, status }, { rejectWithValue, fulfillWithValue }) => {
        try {
            const response = await api.get(`/withdrawals/${sellerId}`, {
                params: { status },
                withCredentials: true
            });
            return fulfillWithValue(response.data);
        } catch (error) {
            return rejectWithValue(error.response.data);
        }
    }
);


export const PaymentReducer = createSlice({
    name: 'payment',
    initialState: {
        successMessage: '',
        errorMessage: '',
        loader: false,
        pendingWithdrows: [],
        withdrawalRequests: [],
        successWithdrows: [],
        totalAmount: 0,
        withdrowAmount: 0,
        pendingAmount: 0,
        availableAmount: 0,
        amountWithdrawn:0,
        requestedAmount:0

    },
    reducers: {
        messageClear: (state, _) => {
            state.errorMessage = ""
            state.successMessage = ""
        }
    },
    extraReducers: (builder) => {
        builder
            .addCase(get_seller_payemt_details.fulfilled, (state, { payload }) => {
                state.totalAmount = payload.totalAmount;
                state.availableAmount = payload.availableAmount;
                state.withdrowAmount = payload.withdrowAmount;
                state.pendingAmount = payload.pendingAmount;
                state.amountWithdrawn = payload.amountWithdrawn;
                state.requestedAmount = payload.requestedAmount;
            })

            .addCase(get_withdrawal_balances.fulfilled, (state, action) => {
              state.withdrawalBalances = action.payload;
                state.status = "Solicitud Enviada";
            })
            .addCase(get_withdrawal_balances.rejected, (state) => {
            
            })
            .addCase(send_withdrowal_request.pending, (state, _) => {
                state.loader = true;
            })
            .addCase(send_withdrowal_request.rejected, (state, { payload }) => {
                state.loader = false;
                state.errorMessage = payload.message;
            })
            .addCase(send_withdrowal_request.fulfilled, (state, { payload }) => {
                state.loader = false;
                state.successMessage = payload.message;
                if (payload.withdrowal) {
                    state.pendingWithdrows = [...state.pendingWithdrows, payload.withdrowal];
                    state.availableAmount -= payload.withdrowal.amount || 0; // Asegurarse que amount esté definido
                    state.pendingAmount = payload.withdrowal.amount || 0; // Asegurarse que amount esté definido
                }
            })
            .addCase(get_payment_request.fulfilled, (state, { payload }) => {
                if (payload && payload.withdrowalRequest) {
                    state.pendingWithdrows = payload.withdrowalRequest;
                }
            })
            .addCase(confirm_payment_request.pending, (state, _) => {
                state.loader = true;
            })
            .addCase(confirm_payment_request.rejected, (state, { payload }) => {
                state.loader = false;
                state.errorMessage = payload.message;
            })
            .addCase(confirm_payment_request.fulfilled, (state, { payload }) => {
                const temp = state.pendingWithdrows.filter(r => r._id !== payload.payment._id);
                state.loader = false;
                state.successMessage = payload.message;
                state.pendingWithdrows = temp;
            })
            
            .addCase(archive_withdrawal.fulfilled, (state, action) => {
                const archivedWithdrawal = action.payload;
                const index = state.withdrawalBalances.findIndex(w => w._id === archivedWithdrawal._id);
                if (index !== -1) {
                  state.withdrawalBalances[index] = archivedWithdrawal;
                }
                state.successMessage = "Retiro archivado exitosamente";
              })
              .addCase(archive_withdrawal.rejected, (state, action) => {
                state.errorMessage = action.payload;
              });
          

            builder
            .addCase(get_withdrawals_by_user_id.fulfilled, (state, { payload }) => {
                state.withdrawalRequests = payload.requests; // Aquí almacenamos las solicitudes en el estado
            });
    }
    

})
export const { messageClear } = PaymentReducer.actions
export default PaymentReducer.reducer