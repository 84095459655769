import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'
import api from '../../api/api'



export const get_withdrawal_balances = createAsyncThunk(
    'get_withdrawal_balances',
    async (sellerId, { rejectWithValue, fulfillWithValue }) => {
      try {
        const response = await api.get(`/withdrawals-balance/${sellerId}`);
       
        return fulfillWithValue(response.data.withdrawalBalances);
      } catch (error) {
        console.error("Error in get_withdrawal_balances:", error);
        return rejectWithValue(error.response.data);
      }
    }
  );
  

  export const archive_withdrawal = createAsyncThunk(
    "payment/archive_withdrawal",
    async (withdrawalId, { rejectWithValue }) => {
      try {
        const response = await api.put(`/withdrawals/archive/${withdrawalId}`);
        return response.data;
      } catch (error) {
        return rejectWithValue(error.response.data);
      }
    }
  );
  






export const PaymentPadelReducer = createSlice({
    name: 'paymentPadel',
    initialState: {
        successMessage: '',
        errorMessage: '',
        loader: false,
        pendingWithdrows: [],
        withdrawalRequests: [],
        withdrawalBalances: [],
      

    },
    reducers: {
        messageClear: (state, _) => {
            state.errorMessage = ""
            state.successMessage = ""
        }
    },
    extraReducers: (builder) => {
     

            builder
            .addCase(get_withdrawal_balances.fulfilled, (state, action) => {
              state.withdrawalBalances = action.payload;
            })
            .addCase(get_withdrawal_balances.rejected, (state) => {
            
            })
 
      

    }
    

})
export const { messageClear } = PaymentPadelReducer.actions
export default PaymentPadelReducer.reducer